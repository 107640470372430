import logo from './logo.svg';
import './App.css';
import Header from './layout/Header';
import { Route, Routes } from 'react-router-dom';
import Home from './components/Home';
import Footer from './layout/Footer';
import Home2 from './components/Home2';
import Home3 from './components/Home3';

function App() {
  return (
    <div className="App">
      <Header />
      <Routes>
      <Route path="/" element={<Home />} />
      <Route path="/home2" element={<Home2 />} />
      <Route path="/home3" element={<Home3 />} />
      </Routes>    
      <Footer/>
      </div>
  );
}

export default App;
