import React, { useRef } from 'react';
import { Button, Col, Row } from 'react-bootstrap';
import Slider from 'react-slick';
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import { faArrowLeft, faArrowRight } from '@fortawesome/free-solid-svg-icons';
import "slick-carousel/slick/slick.css";
import "slick-carousel/slick/slick-theme.css";

import { height } from '@fortawesome/free-solid-svg-icons/fa0';
import Cards from './Cards';

import image2 from '../Images/ij6gGPf3DB9kIHOCOZvM5-transformed.jpeg'
import image1 from '../Images/C9VV1m4rQrTXrLxL2y8iN-transformed.jpeg'
import imagea from '../Images/gqWzgH_A0dPLCITRlSl7w-transformed.jpeg'
import featuresImage from '../Images/OBX3mBaYTRL0knA2pIQSY-transformed.jpeg'
import './Home3.css'

import featuresImageb from '../Images/XJWWMBRueMfaoherWfn2B-transformed.jpeg'
const Home3 = () => {
    const sliderRef = useRef(null);

    const data = [
        {
            home3heading: "HIPAA compliant digital fax service.",
            home3para: "By simply accessing your secure email you can view office faxes anytime, anywhere. In addition, you can send faxes from your email or web-portal.",
            imgs:image1
        },
        {
            home3heading: "Effortless HIPAA-Compliant Faxing",
            home3para: "Send HIPAA compliant, secure faxes from your email account and web portal. Receive faxes to your email account and web portal.",
            imgs: image2
        }
    ];


    const data2 = [
        "Clean, easy to use Faxtone Web portal.",
        "Multiple documents can be attached to each fax, combination of all our supported document types.",
        "No telephone or fax machine or fax modem required.",
        "Enhanced productivity. No need to print documents to fax them. Send them directly from computer.",
        "User-friendly service.",
        "Faxes can be received either in .pdf or .tiff format."
    ]

    const CustomPrevArrow = ({ onClick }) => (
        <button className="slick-arrow slick-prev" onClick={onClick}>
            <FontAwesomeIcon icon={faArrowLeft} />
        </button>
    );

    const CustomNextArrow = ({ onClick }) => (
        <button className="slick-arrow slick-next" onClick={onClick}>
            <FontAwesomeIcon icon={faArrowRight} />
        </button>
    );

    const settings = {
        dots: false, 
        infinite: true,
        speed: 500,
        slidesToShow: 1,
        slidesToScroll: 1,
        fade: true,
        autoplay: true,
        autoplaySpeed: 3000,
        prevArrow: <CustomPrevArrow />,
        nextArrow: <CustomNextArrow />
    };

    const slideNext = () => {
        sliderRef.current.slickNext();
    };

    const slidePrev = () => {
        sliderRef.current.slickPrev();
    };

    return (
        <div className='homepage3 '>


            {/*---------------------------------------slider-container-------------------------------*/}

            <div className="carousel-container ">

                <Slider {...settings} ref={sliderRef}>
                    {data.map((dta, index) => (
                        <div key={index}>
                            <Row className='align-items-center'>
                                <Col lg={6} >
                                    <div className='divcls'>  <h1>{dta.home3heading}</h1>
                                        <p className='py-4'> {dta.home3para}</p>
                                        <Button className='btn'>Read More</Button></div>
                                </Col>
                                <Col lg={5}>
                                    <div className='imgcls'>
                                        <img src={dta.imgs} alt="description" width="900px" height="800px" />
                                    </div>
                                </Col>
                            </Row>

                        </div>
                    ))}
                </Slider>
                <div className="img-grid-buttons">
                    <button className="img-grid-button" onClick={slidePrev}>
                        <FontAwesomeIcon icon={faArrowLeft} />
                    </button>
                    <button className="img-grid-button" onClick={slideNext}>
                        <FontAwesomeIcon icon={faArrowRight} />
                    </button>
                </div>
            </div>
          


   {/*----------------------------------section-2---------------------------*/}




            <div className="section-2 pt-5 ">
                <section class="section__bubble bubble bubble--2 bubble--1 mt-5 pt-5">
                    <div className='section-2-div1 pt-5 text-start'>
                        <h1> Secure Fax Service</h1>
                        <p className='mt-5'>No telephone line, fax machine, or fax modem required. Enhanced productivity. No need to print documents to fax them. Send directly from computer. User-friendly service. Cost-effective operations – Save time, labor, and money.</p>
                    </div>
                    <img className='imge' src={imagea}/>
                </section>

            </div>



            {/*-----------------------------------section-3-----------------------------------*/}
            <div className='section-3'>
                <Row>
                    <Col lg={5} className='col1'>
                        <h1 className='mt-5 pt-5 pb-5'>Faxtone Features</h1>
                        <ul>
                            {data2.map((dta, ind) => (
                                <li key={ind} className='mt-3 lh-lg'>{dta}</li>
                            ))}
                        </ul>

                    </Col>
                    <Col lg={6} className='col2'>
                        <img className='mt-5 pt-5 ' src={featuresImageb}/>
                    </Col>
                </Row>
            </div>


            <div className='section-4'>
                <Cards/> 
            </div>
        </div>
    );
};

export default Home3;
