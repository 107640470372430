import React, { useState } from 'react'
import { Button, Col, Accordion, Row } from 'react-bootstrap'
import img1 from '../Images/phone3.png'
import img2 from '../Images/earthshield.png'
import img3 from '../Images/personal-data-protection.png'
import img4 from '../Images/security.jpeg'
import img5 from '../Images/personal-data-protection.png'
import img6 from '../Images/illustration-of-phone.png'

import { FontAwesomeIcon } from '@fortawesome/react-fontawesome'

// import imga from '../Images/img1.jpg'
import imga from '../Images/imgz.jpg'
// import imga from '../Images/imga.png'
import imgb from '../Images/imgy.jpg'
import imgc from '../Images/imgx.jpg'
import imgd from '../Images/imgw.jpg'
import imge from '../Images/imgv.jpg'

import './Home2.css'

const Home2 = () => {
  return (
    <div className='faxtone-section2'>
        <div className='row1'>
                    <Row className='section-1   px-5'>
                        <Col lg={6} className='text-start col1'>
                            <div className='mx-lg-5'>
                                <svg xmlns="http://www.w3.org/2000/svg" width="1000" height="200" viewBox="0 0 1300 399">
                                    <text id="id" className="cls-1" transform="translate(-350 301)">
                                        <tspan x="0" y="0">HIPAA compliant digital fax</tspan>
                                    </text>
                                </svg>
                                {/*<div className="wrapper">
                            <h1 className="mt-4 lh-base h1-animation"></h1>
                            </div>*/}
                                <p className='fs-5 lh-lg mt-4'>
                                    Highly secure, HIPAA compliant digital fax service.Say goodbye to fax machines,
                                    telephone lines, and paper - reduced carbon footprint.
                                </p>
                                <Button className='btn p-2 mt-4'>Read More</Button>
                            </div>
                        </Col>
                        <Col lg={6}>
                            <img src={img1} width="100%" className="animatio" alt="Animated" />
                        </Col>


                        <div class="circlelayer">
                            <div class="circle-group">
                                <span class="gradient-circle"></span>

                            </div>
                        </div>
                    </Row>
                </div>
        </div>
  )
}

export default Home2
