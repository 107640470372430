import React, { useEffect, useState } from 'react'
import { Navbar, Nav  } from 'react-bootstrap'
import './layout.css'
import imglogo from '../Images/tonelogo2.png'
import { Link, useLocation } from 'react-router-dom';

const Header = () => {
    const [bgImage, setBgImage] = useState('');
    const [colorchange, setColor] = useState('');
    const location = useLocation();
    useEffect(() => {
        // Update background image and color based on path
        if (location.pathname === '/home2') {
         
          setBgImage('transparent')
          setColor('#623c25');
        } else if (location.pathname === '/') {
          // setBgImage('https://codex-themes.com/thegem/sites/lms-landing/wp-content/uploads/2020/11/1.png');
          setColor('#ffffff');
          setBgImage('transparent')
        }
      }, [location.pathname]);
    
    return (
        <div className='header'>
            <Navbar expand="lg" className="bg-body-tertiary"  style={{ backgroundColor: `${bgImage}`, color: `${colorchange}` }}>
                
                    <Navbar.Brand href="#home" className='ms-lg-5'>
                    {/* <h1><span style={{color:"#80d102"}}>Fax</span><span style={{color:"#0acfec"}}>tone</span></h1> */}
                        <img src={imglogo} width="100%"/>
                    </Navbar.Brand>
                   
                    <Navbar.Toggle aria-controls="basic-navbar-nav" />
                    <Navbar.Collapse id="basic-navbar-nav">
                        <Nav className="justify-content-center flex-grow-1 navbar-links" >
                            <Nav.Link href="/" >Home</Nav.Link>
                            <Nav.Link href="/home2">Home2</Nav.Link>
                            <Nav.Link href="/home3">Home3</Nav.Link>                          
                        </Nav>
                    </Navbar.Collapse>
               
            </Navbar>
        </div>
    )
}

export default Header
