import React from 'react';
import styled, { createGlobalStyle, keyframes } from 'styled-components';
import { Container, Row, Col } from 'react-bootstrap';
import '@fontsource/poppins';

// Define keyframes for animation
const rotate = keyframes`
  to {
    transform: var(--anim-rotate);
  }
`;

// Global styles
const GlobalStyle = createGlobalStyle`
  @import url('https://fonts.googleapis.com/css2?family=Poppins:wght@400;500;600;700&family=Mukta:wght@400;500;600;700&display=swap');


  * {
    margin: 0;
    padding: 0;
    box-sizing: border-box;
  }

  .page {
  padding:  200px 0px;
    color: hsl(0, 0%, 100%);
    background-color: #F3FFE3;
 
    display: grid;
    grid-template-areas: "main";
 
 
    font-family: 'Poppins', sans-serif;
  }

  .page__main {
    grid-area: main;
  }
`;

// Styled components
const Main = styled.main`
  display: grid;
  align-items: center;
`;

const Card = styled.article`
  background-color: transparent;
  display: grid;
  position: relative;
  width: 100%;
  aspect-ratio: 1 / 1.5;
  box-shadow: 0 0 2em 0.5em hsla(0, 0%, 0%, 0.25);
  max-width: 22em;
  margin-inline: auto;
`;

const CardTitle = styled.h3`
  color: hsl(0, 0%, 100%);
  position: absolute;
  top: 0.3em;
  right: 1em;
  width: min-content;
  font-size: 35px;
  text-shadow: 4px 4px 6px hsla(0, 0%, 0%, 0.5);
  z-index: 4000;

  @media screen and (min-width: 36em) {
    top: 0.7em;
    right: -1.7em;
  }
`;

const CardContent = styled.div`
  position: relative;

  padding: 2em;
  padding-top: 365px;
  overflow: hidden;

  &::before,
  &::after {
    position: absolute;
    content: "";
   
  }

  &::before {
    --anim-rotate: translate(-50%, -50%) rotate(360deg);
    animation: ${rotate} 5s linear infinite;
    background: linear-gradient(hsl(185, 100%, 50%), hsl(0, 100%, 50%));
    top: 50%;
    left: 50%;
    width: 500%;
    height: 500%;
    transform: translate(-50%, -50%) rotate(0);
  }

  &::after {
    background-color: hsl(234, 41%, 15%);
    top: 0;
    left: 0;
    inset: 0.3em;
    border-radius: 7em 0 0 0;
    box-shadow: 0 0 1em hsla(0, 0%, 0%, 0.5);
  }
`;

const CardDesc = styled.p`
  margin-top: auto;

  font-size: 20px !important;
  position:relative;
   z-index:1;

  @media screen and (min-width: 36em) {
    font-size: 1em;
  }
`;

const CardWord = styled.span`
  position: absolute;
  top: 50%;
  left: 50%;
  z-index:1;
  opacity: 0.15;
  transform: translate(-50%, -50%) rotate(-45deg);
  font-weight: 600;
  font-size: 7em;
  filter: drop-shadow(0.05em 0.05em hsl(0, 0%, 0%));
  user-select: none;
`;

// Main component
const Cards = () => {

    const data = [
        {
            heading: "Security Management Process",
            para: " MDofficeMail has identified and analyzed potential risks to information and has implemented security measures to reduce risks and vulnerabilities",
            fax: "Fax"
        },
        {
            heading: "Security Personnel",
            para: " MDofficeMail has designated a security manager who will be developing and implementing security policies and procedures.",
            fax: "Fax"
        },
        {
            heading: "Quarterly Evaluation",
            para: "Network security scans are performed. Periodic assessment of our procedures and policies is done.",
            fax: "Fax"
        }
    ];

    return (
        <>
            <GlobalStyle />
            <div className="page card-section">
                <Container>
                    <Row className="justify-content-center">
                        <h1 style={{color:"black"}} className='text-center my-5 py-5 fw-bold'>Administrative Safeguards:</h1>
                        {
                            data.map((dta, iud) => {
                                return (
                                    <>
                                        <Col lg={4} md={6} sm={12}>
                                            <Main className="main page__main">
                                                <Card className="card main__card">
                                                    <CardTitle className="card__title lh-base">{dta.heading}</CardTitle>
                                                    <CardContent  className="card__content">
                                                        <CardDesc className="card__desc" style={{ color: "white" }}>
                                                           {dta.para}
                                                        </CardDesc>
                                                        <CardWord className="card__word" aria-hidden="true" style={{ color: "white" }}>
                                                            {dta.fax}
                                                        </CardWord>
                                                    </CardContent >
                                                </Card>
                                            </Main>
                                        </Col>
                                    </>
                                )
                            })
                        }

                    </Row>
                </Container>
            </div>
        </>
    );
};

export default Cards;
