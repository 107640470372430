import React, { useState } from 'react'
import { Button, Col, Accordion, Row } from 'react-bootstrap'
import img1 from '../Images/phone3.png'
import img2 from '../Images/earthshield.png'
import img3 from '../Images/personal-data-protection.png'
import img4 from '../Images/security.jpeg'
import img5 from '../Images/personal-data-protection.png'
import img6 from '../Images/illustration-of-phone.png'

import image2 from '../Images/ij6gGPf3DB9kIHOCOZvM5-transformed.jpeg'
import imagea from '../Images/gqWzgH_A0dPLCITRlSl7w-transformed.jpeg'

import { FontAwesomeIcon } from '@fortawesome/react-fontawesome'

// import imga from '../Images/img1.jpg'
// import imga from '../Images/imgz.jpg'
// import imga from '../Images/imga.png'
import imgb from '../Images/imgx2.jpg'
// import imgb from '../Images/imgy.jpg'
import imga from '../Images/imgy2.jpg'
// import imgc from '../Images/imgx.jpg'
import imgc from '../Images/imgx3.jpg'
// import imgd from '../Images/imgw.jpg'
import imgd from '../Images/imgd2.jpg'
// import imge from '../Images/imgv.jpg'

import imge from '../Images/imge3.jpg'




import { faTwitter, faFacebook, faYoutube, faSquareInstagram } from '@fortawesome/free-brands-svg-icons';
import { faFax, faPhoneAlt, faCheckCircle, faExchangeAlt, faShieldAlt } from '@fortawesome/free-solid-svg-icons';
import './Home.css'
import { faQuoteLeft } from '@fortawesome/free-solid-svg-icons'
const Home = () => {
    const list = [
        "Send HIPAA compliant, secure faxes from your email account and web portal.",
        "Receive faxes to your email account and web portal.",
        "Smart phone app to send and receive faxes from your iPhone/iPad and Android.",
        "Your fax number will never be busy. Multiple faxes can be received simultaneously.",
        "No Downloads, No installations, No hassle!!",
        "Highly secure, HIPAA compliant digital fax service.",
        "Say goodbye to fax machines, telephone lines, and paper - reduced carbon footprint.",
        "Make your faxes available all the time, from anywhere - your office, hospital, or home."
    ]


    const [currentImage, setCurrentImage] = useState(imga);
    const [activeKey, setActiveKey] = useState('0');

    const handleAccordionClick = (key, image) => {
        setActiveKey(key);
        setCurrentImage(image);
    };

    return (
        <div className='faxtone-section'>
            <div >
                <div className='row1'>
                    <Row className='section-1   px-5'>
                        <Col lg={6} className='text-start col1'>
                            <div className='mx-lg-5'>
                              
                          
                            <h1 className="mt-4 lh-base">HIPAA compliant digital fax</h1>
                   
                                <p className='fs-5 lh-lg mt-4'>
                                    Highly secure, HIPAA compliant digital fax service.Say goodbye to fax machines,
                                    telephone lines, and paper - reduced carbon footprint.
                                </p>
                                <Button className='btn p-2 mt-4'>Read More</Button>
                            </div>
                        </Col>
                        <Col lg={6}>
                            <img src={img1} width="100%" className="animatio" alt="Animated" />
                        </Col>


                        
                    </Row>
                </div>
                <Row className='row2 align-items-center'>
                    <Col lg={5} className=''>
                        <img className='ms-5 row2-img1' src={imagea} width="100%" />
                        <img className='ms-5 row2-img2' src={image2} width="100%" />
                    </Col>
                    <Col lg={6} className='text-start ms-5 ps-5'>
                        <div className='ps-5 ms-5'>
                        <div className='ms-lg-5 ps-5'>                
                            <h2 className='mb-5'>Secure Fax Service</h2>
                            <p className='fs-5 lh-lg'>
                                By simply accessing your secure email you can view office faxes anytime,
                                anywhere. In addition, you can send faxes from your email or web-portal.
                                Faxtone secure fax service enables you to easily send faxes to multiple recipients,
                                receive faxes from multiple fax numbers and customize all your faxes. Best of all,
                                Faxtone secure fax service saves you time and money while enhancing your faxing experience.
                            </p>
                            <Button className='mt-4 btn'>Read More</Button>
                        </div>
                        </div>

                    </Col>

                </Row>

                <div className='bg-div3cls'>
                <Row className='row3 text-start p-5'>
                    <Col lg={8} className='p-5 m-5'>
                        <FontAwesomeIcon icon={faQuoteLeft} className='my-5 fa-icon quote-left' />
                        <h1 className='lh-base swift-up'>Send & receive  HIPAA compliant,  faxes from  email   web portal, desktop and smart phone.</h1>
                    </Col>
                </Row>
                </div>
                <div className='row4 '>
                    <Row className=' text-start ps-5'>
                        {/* <Col lg={6} className='col1'>
                        <h1 className='my-5'>Integrate your Faxtone account with any EHR</h1>
                        {
                            list.map((dta, ind) => {
                                return (
                                    <div className='list 1h-lg py-2' key={ind}>
                                        {dta}
                                    </div>
                                )
                            })
                        }
                    </Col> */}


                        
                        {/* <------------------------Accordian Start Section------------------------> */}
                        
                        
                        <Col lg={6} className='col1 list'>
                            <h1>Security & Compliance</h1>
                            <Accordion className='list 1h-lg py-2' activeKey={activeKey} onSelect={(e) => setActiveKey(e)}>
                                <Accordion.Item eventKey="0" onClick={() => handleAccordionClick('0', imga)}>
                                    <Accordion.Header>
                                     <FontAwesomeIcon icon={faFax} className="icon" /> 
                                        <svg  className="icon" xmlns="http://www.w3.org/2000/svg" width="24" height="24" fill="none" viewBox="0 0 24 24" aria-hidden="true" >
                                        <g clip-path="url(#a)"><path fill="currentColor" d="M15.2 20.8v-1.075l.76.76a3.2 3.2 0 1 0 4.525-4.525l-.76-.76H20.8a3.2 3.2 0 0 0 0-6.4h-1.075l.76-.76a3.2 3.2 0 1 0-4.525-4.525l-.76.76V3.2a3.2 3.2 0 0 0-6.4 0v1.075l-.76-.76A3.2 3.2 0 1 0 3.515 8.04l.76.76H3.2a3.2 3.2 0 0 0 0 6.4h1.075l-.76.76a3.2 3.2 0 0 0 4.525 4.525l.76-.76V20.8a3.2 3.2 0 0 0 6.4 0"></path></g>
                                        <defs><clipPath id="a"><path fill="#fff" d="M0 24h24V0H0z"></path></clipPath></defs></svg>
                                            
                                        <span className='span-text'>Do you support International incoming faxes?</span>
                                    </Accordion.Header>
                                    <Accordion.Body>
                                        Local and toll free fax numbers will accept incoming faxes from any origin including an international origin.
                                    </Accordion.Body>
                                </Accordion.Item>
                                <Accordion.Item eventKey="1" onClick={() => handleAccordionClick('1', imgb)}>
                                    <Accordion.Header>
                                      <svg  className="icon" xmlns="http://www.w3.org/2000/svg" width="24" height="24" fill="none" viewBox="0 0 24 24" aria-hidden="true" >
                                        <g clip-path="url(#a)"><path fill="currentColor" d="M15.2 20.8v-1.075l.76.76a3.2 3.2 0 1 0 4.525-4.525l-.76-.76H20.8a3.2 3.2 0 0 0 0-6.4h-1.075l.76-.76a3.2 3.2 0 1 0-4.525-4.525l-.76.76V3.2a3.2 3.2 0 0 0-6.4 0v1.075l-.76-.76A3.2 3.2 0 1 0 3.515 8.04l.76.76H3.2a3.2 3.2 0 0 0 0 6.4h1.075l-.76.76a3.2 3.2 0 0 0 4.525 4.525l.76-.76V20.8a3.2 3.2 0 0 0 6.4 0"></path></g>
                                        <defs><clipPath id="a"><path fill="#fff" d="M0 24h24V0H0z"></path></clipPath></defs></svg> What type of phone number will I get?
                                    </Accordion.Header>
                                    <Accordion.Body>
                                        You can choose a local phone number in the supported area code as well as Toll-Free (800, 888, 877, 866) numbers.
                                    </Accordion.Body>
                                </Accordion.Item>
                                <Accordion.Item eventKey="2" onClick={() => handleAccordionClick('2', imgc)}>
                                    <Accordion.Header>
                                    <svg  className="icon" xmlns="http://www.w3.org/2000/svg" width="24" height="24" fill="none" viewBox="0 0 24 24" aria-hidden="true" >
                                        <g clip-path="url(#a)"><path fill="currentColor" d="M15.2 20.8v-1.075l.76.76a3.2 3.2 0 1 0 4.525-4.525l-.76-.76H20.8a3.2 3.2 0 0 0 0-6.4h-1.075l.76-.76a3.2 3.2 0 1 0-4.525-4.525l-.76.76V3.2a3.2 3.2 0 0 0-6.4 0v1.075l-.76-.76A3.2 3.2 0 1 0 3.515 8.04l.76.76H3.2a3.2 3.2 0 0 0 0 6.4h1.075l-.76.76a3.2 3.2 0 0 0 4.525 4.525l.76-.76V20.8a3.2 3.2 0 0 0 6.4 0"></path></g>
                                        <defs><clipPath id="a"><path fill="#fff" d="M0 24h24V0H0z"></path></clipPath></defs></svg>Do you support email Legal Archiving of all outbound and inbound faxes?
                                    </Accordion.Header>
                                    <Accordion.Body>
                                    If you are using MDofficeMail along with Faxtone, all faxes send and received through email are both online and offline archived.                                    </Accordion.Body>
                                </Accordion.Item>
                                <Accordion.Item eventKey="3" onClick={() => handleAccordionClick('3', imgd)}>
                                    <Accordion.Header>
                                    <svg  className="icon" xmlns="http://www.w3.org/2000/svg" width="24" height="24" fill="none" viewBox="0 0 24 24" aria-hidden="true" >
                                        <g clip-path="url(#a)"><path fill="currentColor" d="M15.2 20.8v-1.075l.76.76a3.2 3.2 0 1 0 4.525-4.525l-.76-.76H20.8a3.2 3.2 0 0 0 0-6.4h-1.075l.76-.76a3.2 3.2 0 1 0-4.525-4.525l-.76.76V3.2a3.2 3.2 0 0 0-6.4 0v1.075l-.76-.76A3.2 3.2 0 1 0 3.515 8.04l.76.76H3.2a3.2 3.2 0 0 0 0 6.4h1.075l-.76.76a3.2 3.2 0 0 0 4.525 4.525l.76-.76V20.8a3.2 3.2 0 0 0 6.4 0"></path></g>
                                        <defs><clipPath id="a"><path fill="#fff" d="M0 24h24V0H0z"></path></clipPath></defs></svg>How do I manage my Faxtone account and MDofficeMail secure email account(s)?
                                    </Accordion.Header>
                                    <Accordion.Body>
                                    An account administration control panel that lets you completely manage all of the user-level functions of your MDofficeMail account. 
                                    </Accordion.Body>
                                </Accordion.Item>
                                <Accordion.Item eventKey="4" onClick={() => handleAccordionClick('4', imge)}>
                                    <Accordion.Header>
                                    <svg  className="icon" xmlns="http://www.w3.org/2000/svg" width="24" height="24" fill="none" viewBox="0 0 24 24" aria-hidden="true" >
                                        <g clip-path="url(#a)"><path fill="currentColor" d="M15.2 20.8v-1.075l.76.76a3.2 3.2 0 1 0 4.525-4.525l-.76-.76H20.8a3.2 3.2 0 0 0 0-6.4h-1.075l.76-.76a3.2 3.2 0 1 0-4.525-4.525l-.76.76V3.2a3.2 3.2 0 0 0-6.4 0v1.075l-.76-.76A3.2 3.2 0 1 0 3.515 8.04l.76.76H3.2a3.2 3.2 0 0 0 0 6.4h1.075l-.76.76a3.2 3.2 0 0 0 4.525 4.525l.76-.76V20.8a3.2 3.2 0 0 0 6.4 0"></path></g>
                                        <defs><clipPath id="a"><path fill="#fff" d="M0 24h24V0H0z"></path></clipPath></defs></svg>What is your policy about sharing customers' fax numbers and email addresses?
                                    </Accordion.Header>
                                    <Accordion.Body>
                                        We respect your privacy. We never rent, sell or share clients' addresses with anyone.
                                    </Accordion.Body>
                                </Accordion.Item>
                            </Accordion>

                        </Col>
                        <Col lg={5} className='col2'>
                            <img src={currentImage} width="100%" alt='imgs' />
                        </Col> 
                        
                        
                        {/* <------------------------Accordian ENd Section------------------------> */}
                        
                       
                    </Row>
                </div>
                
                {/* <Row className='row5'>
                    <Col lg={6}>

                    </Col>
                    <Col lg={6}>
                        <img src="https://kriesi.at/themes/enfold-2017/wp-content/uploads/sites/66/2017/01/mockup.png" width="100%" alt='imgs' />
                    </Col>
                </Row> */}

                {/* <Row>
                    <Col lg={6}>
                        <h1>Security & Compliance</h1>
                        <Accordion defaultActiveKey={['0']} alwaysOpen>
                            <Accordion.Item eventKey="0">
                                <Accordion.Header>Do you support International incoming faxes?</Accordion.Header>
                                <Accordion.Body>
                                    Local and toll free fax numbers will accept incoming faxes from any origin including an international origin. However, international senders will incur regular charges irrespective of whether yours is a local number or toll free number.
                                </Accordion.Body>
                            </Accordion.Item>
                            <Accordion.Item eventKey="1">
                                <Accordion.Header>What type of phone number will I get?</Accordion.Header>
                                <Accordion.Body>
                                    You can choose a local phone number in the supported area code as well as Toll-Free (800, 888, 877, 866) numbers. These are regular phone numbers just like any other US phone number.
                                </Accordion.Body>
                            </Accordion.Item>
                            <Accordion.Item eventKey="2">
                                <Accordion.Header>Can I select my number?</Accordion.Header>
                                <Accordion.Body>
                                    Yes. During signup process you will be asked to check whether fax number with your area code is available or not. After signing up you can log into Faxtone web-portal and select the number from the list of available numbers.
                                </Accordion.Body>
                            </Accordion.Item>
                            <Accordion.Item eventKey="3">
                                <Accordion.Header>Can I transfer my exisitng fax/phone number to your service?</Accordion.Header>
                                <Accordion.Body>
                                    We support porting in local geographical areas that we serve. You can check the possibility of porting your number from our website. We do support porting of toll-free fax number too. There is a one-time fee of $20.00 to offset our costs for porting your number, payable only after successful porting of your number. Please note that you must sign up for Faxtone before you can initiate the porting process                                </Accordion.Body>
                            </Accordion.Item>
                            <Accordion.Item eventKey="3">
                                <Accordion.Header>What is your policy about sharing customers fax numbers and email addresses?</Accordion.Header>
                                <Accordion.Body>
                                    We respect your privacy. We never rent, sell or share clients' addresses with anyone. </Accordion.Body>
                            </Accordion.Item>
                        </Accordion>
                    </Col>
                    <Col lg={4}>

                    </Col>
                </Row> */}
            </div>
        </div>
    )
}

export default Home
